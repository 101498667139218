<template>
  <tbody>
    <tr
      v-for="(offer, index) in offers"
      :class="{ 'hidden-row': !showAll && index > 0 }"
      :key="offer.id"
    >
      <td class="hidden-sm hidden-md hidden-lg">
        <div class="th-mobile">Site</div>
        <div
          :class="{
            'td-mobile': true,
            'visible-xs-block': offers.length > 0,
          }"
        >
          <img :src="logo" :alt="name" />
        </div>
      </td>

      <td class="hidden-xs">
        <div class="td-mobile">
          <img class="logo" :src="logo" :alt="name" />
        </div>
      </td>

      <template v-if="offers.length">
        <template v-if="offer.status == 'available'">
          <td class="name">
            <div class="th-mobile hidden-sm hidden-md hidden-lg">
              Type de chambre
            </div>
            <div class="td-mobile">
              {{ offer.title }}
            </div>
          </td>

          <td class="conditions">
            <div class="th-mobile hidden-sm hidden-md hidden-lg">
              Conditions
            </div>
            <div class="td-mobile">
              <ul class="list-conditions">
                <li :class="{ off: !offer.breakfast, 'icon-coffee': true }">
                  {{ " " }}
                  <span v-if="offer.breakfast">Petit-déj. inclus</span>
                  <span v-else>Petit-déj. en supplément</span>
                </li>

                <li
                  :class="{
                    off: offer.cancellation_status !== 'free',
                    'icon-euro': offer.cancellation_status !== 'none',
                    'icon-block': offer.cancellation_status === 'none',
                  }"
                >
                  {{ offer.cancellation_policy }}
                </li>

                <li
                  :class="{
                    off: offer.payment_status !== 'no_prepaid',
                    'icon-credit-card': offer.payment_status !== 'no_prepaid',
                    'icon-money': offer.payment_status === 'no_prepaid',
                  }"
                >
                  {{ offer.payment_policy }}
                </li>
              </ul>
            </div>
          </td>

          <td class="td-price">
            <div class="th-mobile hidden-sm hidden-md hidden-lg">Prix</div>
            <div class="td-mobile">
              <a :href="offer.url" class="price">{{ offer.formatted_price }}</a>
            </div>
          </td>

          <td class="book">
            <div class="th-mobile hidden-sm hidden-md hidden-lg">Réserver</div>
            <div class="td-mobile">
              <div class="action">
                <a
                  :href="offer.url"
                  class="btn_full"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  Voir l'offre
                </a>
              </div>
            </div>
          </td>
        </template>

        <template v-else>
          <td colspan="3" class="more-on-partner">
            <div class="td-mobile hidden-xs">
              <a :href="offer.url">+ d'infos sur {{ name }}</a>
            </div>
          </td>

          <td class="book">
            <div class="th-mobile hidden-sm hidden-md hidden-lg">Réserver</div>
            <div class="td-mobile">
              <div class="action action-no-offer">
                <a
                  :href="offer.url"
                  :class="{
                    btn_full: true,
                    btn_1: true,
                    primary: offer.status === 'full',
                    outline: offer.status !== 'full',
                  }"
                >
                  <span v-if="offer.status === 'full'">Complet</span>
                  <span v-else>Consulter</span>
                </a>
              </div>
            </div>
          </td>
        </template>
      </template>
    </tr>

    <tr
      class="more-offers"
      v-if="offers.length > 1"
      v-on:click="showAll = !showAll"
    >
      <td colspan="5">
        <div class="td-mobile">
          <button class="btn_1 small">
            {{ offers.length | pluralizedOffers }}
            sur {{ name }}
            <i
              v-bind:class="{
                'icon-down-dir': !showAll,
                'icon-up-dir': showAll,
              }"
            />
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "Seller",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },

    logo: {
      type: String,
      required: true,
    },

    offers: {
      type: Array,
      required: true,
    },

    url: {
      type: String,
    },
  },

  data: () => ({
    showAll: false,
  }),

  computed: {
    offersBtnClasses() {
      return {
        hidden: this.offers.length <= 1,
        btn_1: true,
        "btn-offers": true,
      };
    },
  },

  filters: {
    pluralizedOffers(count) {
      if (count === 1) {
        return "1 offre";
      }
      return `${count} offres`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";
@import "../../../styles/table";
@import "../../../styles/table-availabilities";

.btn_full {
  margin-bottom: 0;
}

tbody tr {
  border-bottom-color: lighten($border-light, 5%);
  border-bottom-width: 1px;

  &:last-child {
    border-bottom-color: darken($border-light, 2%);
  }

  td,
  .td-mobile,
  .th-mobile,
  .logo {
    transition: opacity 0.3s linear 0.1s, padding-top 0.3s ease-in-out,
      padding-bottom 0.3s ease-in-out, max-height 0.5s ease-in-out;
  }

  .td-mobile,
  .th-mobile,
  .logo {
    max-height: 300px;
    overflow: hidden;
  }

  &.hidden-row {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }

    border-bottom-width: 0;

    td {
      padding-top: 0;
      padding-bottom: 0;
    }

    .td-mobile,
    .th-mobile,
    .logo {
      opacity: 0;
      max-height: 0;
    }
  }
}

tbody:last-child tr:last-child {
  border-bottom-width: 0;
}

.action-no-offer {
  .btn_full {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.more-offers {
  cursor: pointer;

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
  }

  td {
    background-color: rgba(200, 200, 200, 0.05);
    vertical-align: middle;

    .td-mobile {
      text-align: center;
    }

    &:hover {
      background-color: rgba(200, 200, 200, 0.1);

      button {
        color: $black !important;
      }
    }
  }

  td,
  .td-mobile {
    padding: 1px;
  }

  button {
    font-size: $size-small;
    background: transparent;
    color: $gray-strong !important;
    padding: 0;
  }
}

.more-on-partner {
  vertical-align: middle;

  a {
    color: $gray-strong;
  }
}
</style>
