<template lang="html">
  <ul class="list-inline">
    <li
      v-for="key in Object.keys(this.sorts)"
      :class="{ active: current == key }"
      :key="key"
    >
      <span
        v-if="current == key"
        data-balloon-pos="down"
        :aria-label="key | currentSortTitle"
        >{{ sorts[key] }}</span
      >
      <a
        v-else
        data-balloon-pos="down"
        :href="generatePath({ sort: key })"
        :aria-label="key | linkToSortTitle"
        v-on:click.prevent="$emit('changeSort', key)"
        >{{ sorts[key] }}</a
      >
    </li>
  </ul>
</template>

<script>
import "balloon-css";

const sorts = {
  score: "popularité",
  price: "prix",
  reviews: "avis",
};

export default {
  name: "SortsList",
  props: {
    current: {
      type: String,
    },

    sortsPaths: {
      type: Object,
    },

    generatePath: {
      type: Function,
    },
  },

  data() {
    return {
      sorts,
    };
  },

  filters: {
    currentSortTitle(key) {
      return `Les hôtels sont triés par ${sorts[key]}`;
    },

    linkToSortTitle(key) {
      return `Trier les hôtels par ${sorts[key]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "../../../styles/variables";

.list-inline {
  display: inline;
  font-weight: 600;

  li {
    padding-left: 0;
    padding-right: 0;

    &:before {
      content: "\00B7";
      padding-left: math.div($spacing, 2);
      padding-right: math.div($spacing, 2);
    }

    &:first-child:before {
      content: "";
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>
