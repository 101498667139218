export const occupancy = (adults) => {
  if (adults === 1) {
    return "1 personne";
  }

  if (adults > 1) {
    return `${adults} personnes`;
  }

  return "";
};

export const pluralize = (count, str) => {
  if (count < 2) {
    return str;
  }

  return `${str}s`;
};

export const textToHtml = (str) => {
  if (!str) {
    return null;
  }

  return str.replace(/\n+/g, "<br />").replace(/ ([:;])/g, "&nbsp;$1");
};
