/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import SearchFormHorizontal from "./index.vue";

document.addEventListener("js-ready", () => {
  const container = "search-form-horizontal";

  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: { SearchFormHorizontal },
    });
  }
});
