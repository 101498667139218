<template>
  <div v-if="checkin && checkout" class="wrapper">
    <h2 class="title">
      Comparaison des prix sur d'autres sites
      <DateRangeSummary
        v-if="checkin && checkout"
        :checkin="checkin"
        :checkout="checkout"
      />
    </h2>
    <div v-if="isLoading || error" class="text-center feedback">
      <Loader v-if="isLoading" large />
      <ErrorMsg v-else-if="error" :error="error" />
    </div>
    <div v-if="!isLoading && !error">
      <sellers :sellers="sellers" />
    </div>
  </div>
</template>

<script>
import Sellers from "./Sellers";
import EventBus from "../../EventBus";
import Loader from "../../shared/Loader";
import ErrorMsg from "../../shared/ErrorMsg";
import DateRangeSummary from "../../shared/DateRangeSummary";

export default {
  name: "HotelSellersAvailabilities",
  components: { Sellers, Loader, ErrorMsg, DateRangeSummary },
  props: {
    hotelId: {
      type: String,
      required: true,
    },
    initialCheckin: {
      type: String,
    },

    initialCheckout: {
      type: String,
    },

    initialAdults: {
      type: Number,
    },
  },

  mounted() {
    EventBus.$on("hotel-search-submit", ({ checkin, checkout, adults }) => {
      this.checkin = checkin;
      this.checkout = checkout;
      this.adults = adults;

      this.fetchSellers();
    });

    this.fetchSellers();
  },

  methods: {
    fetchSellers() {
      if (!this.checkin || !this.checkout) {
        return;
      }

      this.isLoading = true;
      this.sellers = null;
      this.error = null;

      const path = `/hotels/${this.hotelId}/${this.checkin}/${this.checkout}/${this.adults}/sellers_availabilities.json`;

      const finallyFn = () => {
        this.isLoading = false;
      };

      fetch(path, {
        headers: {
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.sellers = data.sellers;
          finallyFn();
        })
        .catch((error) => {
          this.error = error;
          finallyFn();
        });
    },
  },

  data() {
    return {
      checkin: this.initialCheckin,
      checkout: this.initialCheckout,
      adults: this.initialAdults,
      isLoading: false,
      error: null,
      sellers: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/table-availabilities";
</style>
