import * as Sentry from "@sentry/browser";

function browserSupportsAllFeatures() {
  return (
    "Promise" in window &&
    "fetch" in window &&
    "Symbol" in window &&
    "entries" in Object &&
    "padStart" in String.prototype &&
    "find" in Array.prototype
  );
}

let DOMLoaded = false;
let jsFeaturesReady = false;
let jsReadyDispatched = false;

function dispatchIfJsReady() {
  if (DOMLoaded && jsFeaturesReady && !jsReadyDispatched) {
    jsReadyDispatched = true;

    let event;
    // support for IE 11
    if (document.createEvent) {
      event = document.createEvent("Event");
      event.initEvent("js-ready", true, true);
    } else {
      event = new Event("js-ready");
    }

    document.dispatchEvent(event);

    Sentry.addBreadcrumb({
      category: "browserSupport",
      message: "JS Ready",
      level: Sentry.Severity.Debug,
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  DOMLoaded = true;
  dispatchIfJsReady();
});

if (browserSupportsAllFeatures()) {
  jsFeaturesReady = true;
  dispatchIfJsReady();
} else {
  // eslint-disable-next-line
  window.callbackPolyfillsLoaded = () => {
    jsFeaturesReady = true;

    Sentry.addBreadcrumb({
      category: "browserSupport",
      message: "Polyfills loaded",
      level: Sentry.Severity.Debug,
    });

    // Fix for Symbol.iterator in old chrome from https://stackoverflow.com/questions/30836289/for-of-loop-queryselectorall
    NodeList.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];

    dispatchIfJsReady();
  };

  const script = document.createElement("script");

  script.src =
    "https://polyfill.io/v3/polyfill.min.js?version=3.111.0&callback=callbackPolyfillsLoaded&features=Array.prototype.forEach%2CNodeList.prototype.forEach%2CObject.assign%2Cfetch%2CSymbol.iterator%2CString.prototype.padStart%2CObject.entries%2CArray.from%2CPromise%2CArray.prototype.find";
  script.crossorigin = "anonymous";

  document.head.appendChild(script);
}
