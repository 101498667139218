<template>
  <transition name="fade" v-on:after-leave="$emit('afterClose')">
    <div
      class="modal-backdrop"
      v-show="isOpen"
      :class="{ open: isOpen }"
      @click="$emit('onClose')"
    >
      <div
        :class="{ 'modal-dialog': true, open: isOpen, 'modal-large': large }"
        @click.stop
      >
        <div :class="{ 'modal-header': true, 'has-title': !!title }">
          <h4 class="modal-title" v-if="title">{{ title }}</h4>
          <button class="modal-close" @click="$emit('onClose', true)">
            <i class="icon icon-cancel-5" />
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String,
    large: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}
.fade-enter,
.fade-leave-to {
  @media screen and (min-width: $screen-sm-min) {
    opacity: 0;
  }
}
.fade-enter .modal-dialog,
.fade-leave-to .modal-dialog {
  @media screen and (max-width: $screen-xs-max) {
    transform: translateY(100%);
  }
  @media screen and (min-width: $screen-sm-min) {
    transform: translateY(-20%);
  }
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: $background;
  border-radius: 0.3rem;
  transition: 0.5s;
  position: relative;
  z-index: 9999;

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    height: 100%;
    top: 70px; // below nav header
    left: 0;
    padding-bottom: $spacing * 4;
    overflow: scroll;
  }

  @media screen and (min-width: $screen-sm-min) {
    width: auto;
    min-width: 30rem;
    max-width: 956px;
    max-height: 80vh;

    &.modal-large {
      width: 70%;
      min-height: 40vh;
    }
  }
}

.modal-header {
  padding: $spacing * 1.5 $spacing * 2;
  position: relative;
  font-size: $size4;

  &.has-title {
    border-bottom: 1px solid $border-light;
  }
}

.modal-title {
  color: $black-light;
  margin: 0 $spacing * 3 0 0;
}

.modal-close {
  background: none;
  border: none;
  position: absolute;
  padding: 0;
  top: $spacing * 1.5;
  right: $spacing;
  outline: none;
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
}

.modal-body {
  color: $black;
  padding: $spacing * 2 $spacing * 2;
}
</style>
