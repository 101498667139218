<template>
  <i v-bind:class="classNames"></i>
</template>

<script>
export default {
  name: "Loader",
  props: {
    large: {
      type: Boolean,
    },
  },

  computed: {
    classNames() {
      return {
        "icon-spinner": true,
        "animate-spin": true,
        large: this.large,
      };
    },
  },
};
</script>

<style lang="scss">
.large {
  font-size: 4rem;
}
</style>
