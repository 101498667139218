<template>
  <div class="form">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <i class="icon-calendar-7" />
            Dates de séjour
          </label>
          <DateRangeInput
            :checkin="checkin"
            :checkout="checkout"
            @dates-change="handleDatesChange"
            ref="dateRangeInput"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>
            <i class="icon-adult" />
            Nombre de personnes
          </label>
          <QuantityChange
            v-bind:initial-value="this.adults"
            v-bind:min="1"
            v-bind:max="4"
            data-sync-id="search_adults"
            @change="handleAdultsChanged"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="sidebar-group-link">
          <label>8 chambres ou plus ?</label>
          <br />
          <a :href="groupUrlBase" rel="nofollow noopener" target="_blank"
            >Optez pour un devis personnalisé.</a
          >
        </div>
      </div>
    </div>

    <br />
    <button class="btn_full" @click="submit">Voir les tarifs</button>
  </div>
</template>

<script>
import EventBus from "../../EventBus";
import DateRangeInput from "../../shared/DateRangeInput";
import QuantityChange from "../../shared/quantity_change/index.vue";

export default {
  name: "Form",
  props: ["checkin", "checkout", "adults", "groupUrlBase"],
  components: {
    DateRangeInput,
    QuantityChange,
  },

  methods: {
    handleDatesChange({ checkin, checkout }) {
      this.$emit("dates-change", { checkin, checkout });
    },

    handleAdultsChanged(adults) {
      if (this.adults) {
        this.adults = adults;
      }
    },

    submit({ validate = true }) {
      if (validate && !this.$refs.dateRangeInput.isValid()) {
        return;
      }

      EventBus.$emit("hotel-search-submit", {
        checkin: this.checkin,
        checkout: this.checkout,
        adults: this.adults,
      });
    },
  },

  mounted() {
    this.submit({ validate: false });
  },

  data() {
    return {};
  },
};
</script>

<style lang="css" scoped></style>
