import throttle from "lodash/throttle";

const bind = () => {
  const header = document.getElementById("header");

  function stickyHeader() {
    header.classList.toggle("sticky", window.scrollY > 0);
  }

  if (header) {
    window.addEventListener("scroll", throttle(stickyHeader, 250));
  }
};

document.addEventListener("DOMContentLoaded", bind);
