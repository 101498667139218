<template>
  <div class="strip_all_tour_list">
    <div class="row row-no-gutters equal">
      <div class="col-xs-12 col-sm-4 photo-wrapper">
        <a class="hotel-photo" :href="url | toDescription" :title="name">
          <img :alt="name" :src="imageUrl" loading="lazy" />
        </a>
      </div>

      <div class="col-xs-12 col-sm-5 detail-wrapper">
        <div class="tour_list_desc">
          <div class="hotel-header">
            <div class="hotel-name">
              <h3>
                <strong>
                  <a :href="url | toDescription">{{ name }}</a>
                </strong>
              </h3>

              <div class="rating">
                <i class="icon-star voted" v-for="n in stars" :key="n" />
              </div>
            </div>
            <div
              v-if="reviewScore"
              :class="['score', `score-${Math.floor(this.reviewScore)}`]"
            >
              <span class="score-note">{{ reviewScore }}</span>
              <span class="score-label">{{ reviewLabel }}</span>
            </div>
          </div>

          <p>{{ excerpt }}</p>

          <div v-if="quartersIds.length" class="quarters">
            {{ "Quartier" }} :
            <QuarterLink
              v-for="quarterId in quartersIds"
              :key="quarterId"
              v-bind="quarters.find(({ id }) => id === quarterId)"
            />
          </div>

          <ul class="hotel-facilities" v-if="facilitiesIds.length">
            <Facility
              v-for="facilityId in facilitiesIds"
              :key="facilityId"
              v-bind="siteFacilities.find(({ id }) => id === facilityId)"
            />
          </ul>
        </div>
      </div>

      <div
        class="col-xs-10 col-xs-offset-1 col-sm-3 col-sm-offset-0 prices-wrapper"
      >
        <Price
          :price="price"
          :status="status"
          :hotelUrl="url | toAvailabilities"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Facility from "./Facility";
import Price from "./Price";
import QuarterLink from "./QuarterLink";

import {
  arrayOfNumberValidator,
  isString,
  isNumber,
} from "../../../utils/validators";

export default {
  name: "DetailedCard",
  components: { Facility, Price, QuarterLink },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    stars: {
      type: Number,
    },
    reviewScore: {
      type: Number,
    },
    reviewLabel: {
      type: String,
    },
    excerpt: {
      type: String,
    },
    quartersIds: {
      type: Array,
      validator: arrayOfNumberValidator,
      required: true,
    },
    facilitiesIds: {
      type: Array,
      validator: arrayOfNumberValidator,
      required: true,
    },
    price: {
      type: String,
    },
    status: {
      type: String,
      required: true,
    },
    quarters: {
      type: Array,
      required: true,
      validator: (array) =>
        array.every(
          ({ id, name, title, url }) =>
            isNumber(id) && [name, title, url].every(isString)
        ),
    },
    siteFacilities: {
      type: Array,
      required: true,
      validator: (array) =>
        array.every(
          ({ id, label, icon }) => isNumber(id) && [label, icon].every(isString)
        ),
    },
  },

  filters: {
    toDescription: (url) => `${url}#hotel_anchor`,
    toAvailabilities: (url) => `${url}#hotel-rooms-availabilities`,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.quarters {
  margin-bottom: $spacing;
}

.tour_list_desc {
  border: none;
  padding: 10px 0 0;
}

@mixin review-score($color) {
  color: $color;
  .score-note {
    color: $color;
    border-color: $color;
  }
}

.score-10,
.score-9 {
  @include review-score(rgb(50, 147, 74));
}

.score-8 {
  @include review-score(rgb(85, 152, 67));
}

.score-7 {
  @include review-score(rgb(120, 170, 70));
}

.score-6 {
  @include review-score(rgb(150, 162, 58));
}
</style>
