document.addEventListener("DOMContentLoaded", () => {
  const announce = document.getElementById("announce");

  if (announce) {
    const header = announce.previousSibling;

    if (header) {
      announce.style.top = `${header.offsetHeight}px`;
      announce.style.opacity = 1;
    }
  }
});
