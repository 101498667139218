/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import HotelSellersAvailabilities from "./index.vue";

document.addEventListener("js-ready", () => {
  const container = "hotel-sellers-availabilities";

  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: { HotelSellersAvailabilities },
      data() {
        return {
          hotelId: this.hotelId,
        };
      },
    });
  }
});
