<template>
  <div class="hotel-room">
    <div v-if="isLoading || error" class="text-center feedback">
      <Loader v-if="isLoading" large />
      <ErrorMsg v-else-if="error" :error="error" @retry="fetchDetails" />
    </div>

    <div class="room-row" v-if="!isLoading && !error">
      <section class="room-details">
        <h5 class="room-infos">
          <i class="icon-adult" aria-hidden="true" />
          {{ maxOccupancy | occupancy }}

          <span class="room-gray" v-if="surface"> – {{ surface }} m²</span>
        </h5>

        <p v-if="description" v-html="descriptionHtml" />

        <section v-if="amenities.length" class="facilities">
          <h5>Caractéristiques et équipements de la chambre</h5>
          <ul>
            <li v-for="value in amenities" :key="value">
              {{ value }}
            </li>
          </ul>
        </section>
      </section>
      <section class="room-photos">
        <hotel-photos
          v-if="photos.large"
          v-bind:photos="photos.large"
          v-bind:thumbnails="photos.thumbnails"
        />
      </section>
    </div>
  </div>
</template>

<script>
import ErrorMsg from "../../shared/ErrorMsg";
import Loader from "../../shared/Loader";
import HotelPhotos from "../photos/index.vue";
import { occupancy, textToHtml } from "../../../utils/filters";

export default {
  name: "RoomDetails",
  components: { HotelPhotos, ErrorMsg, Loader },
  props: {
    url: {
      type: String,
      required: true,
    },

    surface: {
      type: Number,
      default: null,
    },

    maxOccupancy: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.fetchDetails();
  },

  methods: {
    fetchDetails() {
      const finallyFn = () => {
        this.isLoading = false;
      };

      return fetch(this.url, {
        headers: {
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.error = data.error.message;
          } else {
            this.description = data.description;
            this.amenities = data.amenities;
            this.photos = data.photos;
          }

          finallyFn();
        })
        .catch(() => {
          this.error = "Une erreur est survenue.";
          finallyFn();
        });
    },
  },

  data() {
    return {
      isLoading: true,
      error: null,
      description: null,
      amenities: [],
      photos: {
        large: [],
        thumbnails: [],
      },
    };
  },

  computed: {
    descriptionHtml() {
      return textToHtml(this.description);
    },
  },

  filters: {
    occupancy,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.room {
  @media screen and (min-width: $screen-md-min) {
    &-row {
      display: flex;
      flex-direction: row;
    }

    &-details {
      width: 45%;
    }

    &-photos {
      width: 55%;
    }
  }

  &-infos {
    margin-top: 0;
  }

  &-gray {
    color: $gray-strong;
  }
}

.details p {
  color: $black-light;
}

ul {
  margin: 0;
  padding: 0;
}

.facilities {
  li {
    display: inline-block;
    margin: 0;
    padding: 0;

    &:after {
      padding: 0 0.25em;
      content: "·";
    }
  }
}

.hotel-room ::v-deep .hotel-photos {
  margin-bottom: $spacing * 1.5;

  @media screen and (max-width: $screen-sm-max) {
    .slide {
      max-height: 300px;
      height: auto;

      img {
        max-width: 100%;
      }
    }
  }

  @media screen and (min-width: $screen-md-min) {
    // modal height minus modal header (45px), bottom margin (8px), thumbnails (100px) - padding
    max-height: calc(70vh - 45px - #{$spacing} - 120px - 2 * #{$spacing});
    //
    .slide {
      img {
        object-fit: cover;
      }
    }
  }
}

.hotel-room ::v-deep .thumbnails {
  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
}
</style>
