<template>
  <div v-if="checkin && checkout">
    <div class="wrapper">
      <h2 class="title" v-if="hotels && checkin && checkout">
        Prix des hôtels
        <DateRangeSummary
          v-if="checkin && checkout"
          :checkin="checkin"
          :checkout="checkout"
        >
          <template v-slot:suffix>pour {{ adults | occupancy }}</template>
        </DateRangeSummary>
      </h2>
      <h2 v-else-if="isLoading || error" class="title">
        Vérification des prix
        <DateRangeSummary
          v-if="checkin && checkout"
          :checkin="checkin"
          :checkout="checkout"
        />
      </h2>
      <h2 v-else class="title">
        Veuillez entrer vos dates de voyage.
      </h2>

      <div v-if="isLoading || error" class="text-center feedback">
        <Loader v-if="isLoading" large />
        <ErrorMsg v-else-if="error" :error="error" />
      </div>

      <div v-if="resultsCount && !isLoading && !error" class="search-meta">
        <p class="results-count">
          {{ resultsCount | availabilitiesCount }} à vos dates.
        </p>

        <div class="sorts" v-if="resultsCount > 1">
          Trier par
          <SortsList
            :current="sort"
            :generatePath="generatePath"
            @changeSort="onChangeSort"
          />
        </div>
      </div>
    </div>

    <div :class="{ 'is-refreshing': isRefreshing }">
      <DetailedCard
        v-for="hotel in hotels"
        :key="hotel.id"
        v-bind="hotel"
        :quarters="quarters"
        :site-facilities="siteFacilities"
      />
    </div>
  </div>
</template>

<script>
import EventBus from "../../EventBus";
import DetailedCard from "../../hotels/DetailedCard";
import DateRangeSummary from "../../shared/DateRangeSummary";
import SortsList from "./SortsList";
import ErrorMsg from "../../shared/ErrorMsg";
import Loader from "../../shared/Loader";
import { occupancy } from "../../../utils/filters";
import {
  handleServerError,
  extractHTTPErrorMessage,
} from "../../../utils/http";

export default {
  name: "SearchResults",
  components: { DateRangeSummary, ErrorMsg, Loader, DetailedCard, SortsList },
  props: {
    initialCheckin: {
      type: String,
    },
    initialCheckout: {
      type: String,
    },
    initialAdults: {
      type: Number,
    },

    initialFilters: {
      type: Object,
    },
  },

  mounted() {
    EventBus.$on("search-submit", this.onSearchSubmit);
    EventBus.$on("search-submit-error", this.onSearchSubmitError);
    EventBus.$on("search-update-success", this.onSearchUpdateSuccess);

    this.setupInitialFormParams();
    this.fetchResults();
  },

  methods: {
    onSearchSubmit({ checkin, checkout, adults }) {
      this.removeHotelsStatic();

      if (
        checkin !== this.checkin ||
        checkout !== this.checkout ||
        adults !== this.adults
      ) {
        this.hotels = [];
      }

      this.checkin = checkin;
      this.checkout = checkout;
      this.adults = adults;
      this.isLoading = true;
      this.isRefreshing = true;
    },

    onSearchUpdateSuccess({ checkin, checkout, adults, formParams }) {
      this.checkin = checkin;
      this.checkout = checkout;
      this.adults = adults;
      this.formParams = formParams;

      this.fetchResults();
    },

    onSearchSubmitError({ error }) {
      this.error = error;
      this.isLoading = false;
      this.isRefreshing = false;
    },

    onChangeSort(sort) {
      this.sort = sort;
      this.isRefreshing = true;

      this.fetchResults();
    },

    fetchResults() {
      if (!this.checkin || !this.checkout) {
        // From a landing page, there are no checkin dates until user start a search
        return;
      }

      clearTimeout(this.refreshTimeout);
      this.isLoading = true;
      this.resultsCount = null;
      this.error = null;

      this.fetchNewResults();
    },

    fetchNewResults() {
      fetch(this.generatePath(), {
        headers: {
          accept: "application/json",
        },
      })
        .then(handleServerError)
        .then((response) => response.json())
        .then(this.handleResponse)
        .then(() => {
          if (!this.isLoading) {
            EventBus.$emit("search-update-finished", { url: this.url });
          }
        })
        .catch((error) => {
          return extractHTTPErrorMessage(error).then((message) => {
            this.isLoading = false;
            this.isRefreshing = false;
            this.error = message;
          });
        });
    },

    handleResponse({
      search: { attributes, hotels, resultsCount, finishedAt, sort, url },
      quarters,
      siteFacilities,
    }) {
      this.checkin = attributes.checkin;
      this.checkout = attributes.checkout;
      this.adults = attributes.adults;
      this.sort = sort;
      this.url = url;

      this.resultsCount = resultsCount;
      this.hotels = hotels;
      this.quarters = quarters;
      this.siteFacilities = siteFacilities;

      if (finishedAt) {
        this.isLoading = false;
      } else {
        this.isLoading = true;
        this.refreshTimeout = setTimeout(this.fetchNewResults.bind(this), 2000);
      }

      this.isRefreshing = false;
    },

    removeHotelsStatic() {
      // remove static hotels when we're coming from a static crosspage
      const hotelsStatic = document.getElementById("hotels_static");
      if (hotelsStatic) {
        hotelsStatic.remove();
      }
    },

    generatePath({ sort } = {}) {
      const sortParam = sort ?? this.sort;

      this.formParams.append("search[checkin]", this.checkin);
      this.formParams.append("search[checkout]", this.checkout);
      this.formParams.append("search[adults]", this.adults);
      if (sortParam) {
        this.formParams.append("sort", sortParam);
      }

      return `/recherche?${this.formParams.toString()}`;
    },

    setupInitialFormParams() {
      if (!this.initialFilters) {
        return;
      }

      Object.entries(this.initialFilters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // If the value is an array, append each item with brackets
          value.forEach((val, index) => {
            this.formParams.append(`search[${key}][]`, val);
          });
        } else {
          // Otherwise, append the value directly
          this.formParams.append(`search[${key}]`, value);
        }
      });
    },
  },

  beforeDestroy() {
    clearTimeout(this.refreshTimeout);
  },

  data() {
    return {
      checkin: this.initialCheckin,
      checkout: this.initialCheckout,
      adults: this.initialAdults,
      formParams: new URLSearchParams(),
      sort: null,
      isLoading: false,
      isRefreshing: false,
      error: null,
      resultsCount: null,
      hotels: [],
      quarters: [],
      siteFacilities: [],
      refreshTimeout: null,
      url: null,
    };
  },

  watch: {
    isLoading(newValue) {
      if (newValue) {
        return;
      }

      if (this.resultsCount === 0 && !this.error) {
        this.error = "Aucun hôtel n'est disponible à ces dates.";
      }
    },

    checkin(checkin, prev) {
      if (checkin !== prev) {
        this.hotels = [];
      }
    },

    checkout(checkout, prev) {
      if (checkout !== prev) {
        this.hotels = [];
      }
    },
  },

  filters: {
    occupancy,
    availabilitiesCount: (count) => {
      if (count === 0) {
        return "Aucun hôtel n'est disponible";
      }

      if (count === 1) {
        return "Un seul hôtel est disponible";
      }

      return `${count} hôtels sont disponibles`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/variables";
@import "../../../styles/table-availabilities";

.wrapper {
  margin-bottom: $spacing * 4;
}

.is-refreshing {
  opacity: 0.4;
}

.search-meta {
  padding: $spacing * 2;
  font-size: $size6;

  @media screen and (max-width: $screen-xs-max) {
    text-align: center;
  }

  @media screen and (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;
  }
}

.sorts {
  @media screen and (max-width: $screen-xs-max) {
    margin-top: $spacing;
  }
}

.results-count {
  font-weight: 600;
}
</style>
