/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import SearchResults from "./index.vue";

document.addEventListener("js-ready", () => {
  const container = "search-results";

  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: { SearchResults },
      data() {
        return {};
      },
    });
  }
});
