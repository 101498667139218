import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

Sentry.init({
  dsn: `https://${window.gon.sentry_dsn}@o398968.ingest.sentry.io/5255427`,
  environment: window.gon.env,
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
      logErrors: window.gon.env === "development",
    }),
  ],
});
