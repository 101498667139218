document.addEventListener("DOMContentLoaded", () => {
  const toTop = document.getElementById("toTop");

  if (!toTop) {
    return;
  }

  toTop.dataset.hidden = window.pageYOffset < 100;

  window.addEventListener("scroll", () => {
    toTop.dataset.hidden = window.pageYOffset < 100;
  });

  toTop.addEventListener("click", () => {
    document.body.scrollIntoView({ behavior: "smooth" });
  });
});
