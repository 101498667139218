/* eslint-disable no-unused-vars */

import Vue from "vue";
import VueAgile from "vue-agile";
import HotelPhotos from "./index.vue";

Vue.use(VueAgile);

document.addEventListener("js-ready", () => {
  if (document.getElementById("hotel-photos")) {
    const hotelPhotos = new Vue({
      el: "#hotel-photos",
      components: { HotelPhotos },
    });
  }
});
