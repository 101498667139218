<template>
  <div class="tab-pane active">
    <h2 class="h3">{{ title }}</h2>
    <form
      action="/recherche"
      name="search-form-horizontal"
      v-on:submit.prevent="submit"
    >
      <div class="row">
        <div class="col-sm-5">
          <div class="form-group">
            <label class="control-label">
              <i class="icon-calendar-7" />
              Dates de séjour
            </label>
            <DateRangeInput
              :checkin="checkin"
              :checkout="checkout"
              @dates-change="handleDatesChange"
              ref="dateRangeInput"
            />
            <input type="hidden" name="search[checkin]" :value="checkin" />
            <input type="hidden" name="search[checkout]" :value="checkout" />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label class="control-label">
              <i class="icon-adult" />
              Nombre de personnes
            </label>
            <QuantityChange
              name="search[adults]"
              v-bind:initial-value="this.adults"
              v-bind:min="1"
              v-bind:max="4"
              @change="handleAdultsChanged"
            />
          </div>
        </div>
      </div>

      <hr />

      <Button :isLoading="isLoading" />

      <p class="home-group-link">
        <a :href="groupUrlBase" rel="nofollow noopener" target="_blank"
          >Réservation d'au moins 8 chambres ?</a
        >
      </p>
    </form>
  </div>
</template>

<script>
import DateRangeInput from "../../shared/DateRangeInput";
import QuantityChange from "../../shared/quantity_change/index.vue";
import Button from "../../shared/SearchButton";

export default {
  name: "SearchFormHorizontal",
  props: {
    title: {
      type: String,
      required: true,
    },

    initialCheckin: {
      type: String,
      required: false,
    },

    initialCheckout: {
      type: String,
      required: false,
    },

    initialAdults: {
      type: Number,
      required: false,
    },

    groupUrlBase: {
      type: String,
      required: true,
    },
  },

  components: {
    Button,
    DateRangeInput,
    QuantityChange,
  },

  methods: {
    handleDatesChange({ checkin, checkout }) {
      this.checkin = checkin;
      this.checkout = checkout;
    },

    handleAdultsChanged(adults) {
      this.adults = adults;
    },

    submit() {
      if (!this.$refs.dateRangeInput.isValid()) {
        return;
      }

      this.isLoading = true;

      const form = document.forms["search-form-horizontal"];
      form.submit();
    },
  },

  data() {
    return {
      checkin: this.initialCheckin,
      checkout: this.initialCheckout,
      adults: this.initialAdults || 2,
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";
</style>
