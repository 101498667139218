<template>
  <li class="facility">
    <a data-balloon-pos="up" :aria-label="label">
      <i :class="icon" />
    </a>
  </li>
</template>

<script>
import "balloon-css";

export default {
  name: "Facility",
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "../../../styles/_variables";

.facility {
  margin-bottom: math.div($spacing, 2);
}
</style>
