/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import DateRangePicker from "vue-mj-daterangepicker";
import HotelSearch from "./index.vue";

Vue.use(DateRangePicker);

document.addEventListener("js-ready", () => {
  if (document.getElementById("hotel-search")) {
    new Vue({
      el: "#hotel-search",
      components: { HotelSearch },
    });
  }
});
