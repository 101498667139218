<template>
  <div class="search-by-name">
    <div v-if="isLoading || error" class="text-center feedback">
      <Loader v-if="isLoading" large />
      <ErrorMsg v-else-if="error" :error="error" />
    </div>

    <div v-if="!isLoading && !error">
      <input
        type="search"
        class="form-control"
        v-model="query"
        placeholder="Nom de l'hôtel"
        ref="input"
      />
      <div class="hotels-wrapper">
        <ul v-if="hotels.length" class="hotels">
          <li
            v-for="hotel in filteredHotels"
            :key="hotel.id"
            :class="{ hotel: true, linkable: !hotel.url }"
          >
            <a :href="hotel.url" v-if="hotel.url">{{ hotel.name }}</a>
            <div v-else class="no-link">{{ hotel.name }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import words from "lodash/words";
import kebabCase from "lodash/kebabCase";
import ErrorMsg from "../shared/ErrorMsg";
import Loader from "../shared/Loader";

const emptyResult = {
  id: "no-result",
  name: "Aucun hôtel ne correspond à votre recherche.",
};

export default {
  name: "SearchByName",
  components: { ErrorMsg, Loader },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
    },
    hotels: {
      type: Array,
      required: true,
    },
  },

  updated() {
    if (!this.isLoading && !this.error && !this.query) {
      this.focusInput();
    }
  },

  methods: {
    focusInput() {
      this.$refs.input.focus();
    },

    tokenize(str) {
      return words(str).map(kebabCase);
    },
  },

  data() {
    return {
      query: null,
    };
  },

  computed: {
    filteredHotels() {
      if (!this.query) {
        return this.hotels;
      }

      const queryTokens = this.tokenize(this.query);

      const filtered = this.hotels.filter(({ name }) => {
        const normalizedName = kebabCase(name);
        return queryTokens.some((token) => normalizedName.indexOf(token) > -1);
      });

      if (filtered.length) {
        return filtered;
      }

      return [emptyResult];
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "../../styles/_variables";

.hotels-wrapper {
  overflow-y: scroll;

  @media screen and (min-width: $screen-sm-min) {
    height: 200px;
  }
}

.hotels {
  list-style-type: none;
  padding: 0;
  font-size: $size6;
  overflow: hidden;
  border: 1px solid $border-light;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}

.hotel {
  line-height: 1.5em;
  border-bottom: 1px solid $border-light;

  &:last-child {
    border-bottom: none;
  }

  a {
    transition: all 0.2s;

    &:hover {
      color: rgba($white, 0.96);
      background-color: $tertiary;
    }
  }

  a,
  .no-link {
    display: block;
    padding: math.div($spacing, 2);
  }
}
</style>
