export function elementTopIntoView(el) {
  if (!el.getBoundingClientRect) {
    return undefined;
  }

  const { height: headerHeight } = document
    .getElementById("header")
    .getBoundingClientRect();

  const { top } = el.getBoundingClientRect();

  // should be below header and not too low on the window
  return top >= headerHeight + 20 && top < 220;
}

export function scrollTopIntoView(el) {
  if (!el.scrollIntoView) {
    return;
  }

  el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "start",
  });
}
