<template>
  <span class="date-range-summary">
    du {{ checkinString }} au {{ checkoutString }} ({{ nightsString }})
    <slot name="suffix" />
  </span>
</template>

<script>
import { differenceInDays, parseISO } from "date-fns";
import { formatDate } from "../../../utils/date";

export default {
  name: "DateRangeSummary",
  props: {
    checkin: {
      type: String,
      required: true,
    },

    checkout: {
      type: String,
      required: true,
    },
  },

  computed: {
    checkinString() {
      return formatDate(this.checkin);
    },

    checkoutString() {
      return formatDate(this.checkout);
    },

    nightsString() {
      const difference = differenceInDays(
        parseISO(this.checkout),
        parseISO(this.checkin)
      );

      return `${difference} nuit${difference > 1 ? "s" : ""}`;
    },
  },
};
</script>

<style lang="css" scoped></style>
