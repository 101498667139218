const bind = () => {
  const buttons = document.getElementsByClassName("open_close");
  const menu = document.getElementsByClassName("main-menu")[0];
  const layer = document.getElementsByClassName("layer")[0];

  [...buttons].forEach((button) => {
    button.addEventListener("click", () => {
      menu.classList.toggle("show");
      button.classList.toggle("active");
      layer.classList.toggle("layer-is-visible");

      // collapse opened submenus for further menus openings
      if (!menu.classList.contains("show")) {
        const submenus = document.querySelectorAll(".submenu .show_normal");
        [...submenus].forEach((submenu) => {
          submenu.classList.remove("show_normal");
        });
      }
    });
  });

  // sync main menu icon state with small mobile close icon
  const mobileClose = document.getElementById("close_in");
  if (mobileClose) {
    mobileClose.addEventListener("click", () => {
      buttons[0].classList.remove("active");
    });
  }

  const submenuButtons = document.getElementsByClassName("show-submenu");
  [...submenuButtons].forEach((button) => {
    button.addEventListener("click", () => {
      button.nextSibling.classList.toggle("show_normal");
    });
  });
};

document.addEventListener("js-ready", bind);
