/* eslint-disable import/prefer-default-export */

export class ServerError extends Error {}

export function handleServerError(response) {
  if (response.ok) {
    return response;
  }

  return response.json().then(({ error }) => {
    throw new ServerError(error);
  });
}

export function extractHTTPErrorMessage(error) {
  if (error instanceof ServerError) {
    return Promise.resolve(error.message);
  }

  let message = "Une erreur est survenue. Veuillez réessayer.";

  const matchError = error.message.match(/^(\w+Error)/);
  if (matchError) {
    message += ` (${matchError[1]})`;
  }

  return Promise.resolve(message);
}
