<template>
  <tfoot>
    <tr class="summary">
      <td class="sum" colspan="3">
        <div class="price" v-if="roomsCount > 0">
          {{ roomsCount | roomsSummary }}
          pour <strong>{{ totalPrice }} €</strong>
        </div>

        <em
          >La réservation sera opérée par notre partenaire
          <strong>Booking.com</strong>.</em
        >
      </td>
      <td class="book">
        <div
          class="action"
          data-balloon-pos="up"
          :aria-label="
            roomsCount === 0 &&
            'Vous devez sélectionner au moins une chambre avant de poursuivre'
          "
        >
          <button
            class="btn_full"
            type="submit"
            @click="submit"
            :disabled="roomsCount < 1"
          >
            <Loader v-if="isSubmitting" />
            <template v-else>Réserver</template>
          </button>
        </div>
      </td>
    </tr>
  </tfoot>
</template>

<script>
import "balloon-css";

import Loader from "../../shared/Loader";

export default {
  name: "Summary",
  components: { Loader },
  props: {
    roomsCount: {
      type: Number,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },

  methods: {
    submit() {
      this.isSubmitting = true;

      setTimeout(() => {
        this.isSubmitting = false;
      }, 2000);
    },
  },

  data() {
    return {
      isSubmitting: false,
    };
  },

  filters: {
    roomsSummary(count) {
      if (count === 1) {
        return "1 chambre";
      }

      if (count > 1) {
        return `${count} chambres`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";
@import "../../../styles/table";

tfoot {
  td {
    vertical-align: middle;
  }
}

.summary {
  background: transparent;
}

button {
  margin-bottom: 0;
}

.sum {
  text-align: right;
  color: $gray-strong;

  .price {
    font-size: $size3;
    font-weight: bold;

    strong {
      color: $black;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .sum {
    vertical-align: middle;
    margin-top: 8px;
    font-size: $size-text;
  }

  .summary {
    display: block;
    width: 100%;

    td {
      display: block;
      padding: 0 0 12px;
      width: 100%;

      .price {
        display: block;
        padding: 12px 12px 0;
      }

      &.book {
        padding: $spacing $spacing * 4;
        background-color: inherit;
      }
    }
  }
}
</style>
