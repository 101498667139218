/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import QuantityChange from "./index.vue";

function mount() {
  const elements = document.getElementsByTagName("quantity-change");

  [...elements].forEach((el) => {
    new Vue({
      el,
      components: { QuantityChange },
    });
  });
}

document.addEventListener("js-ready", mount);
document.addEventListener("roomsAvailabilitiesLoaded", mount);
