import { format } from "date-fns";

export const iso8601 = (date) => format(date, "yyyy-MM-dd");

export const formatDate = (date, inputOptions = {}) => {
  const options = {
    month: "short",
    ...inputOptions,
  };

  return new Date(date).toLocaleString("fr-FR", {
    weekday: "short",
    day: "numeric",
    month: options.month,
  });
};
