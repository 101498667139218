<template>
  <a :href="url" :title="title">{{ name }}</a>
</template>

<script>
export default {
  name: "QuarterLink",
  props: {
    name: {
      type: String,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  &:after {
    content: ", ";
  }

  &:last-child:after {
    content: "";
  }
}
</style>
