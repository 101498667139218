<template>
  <div class="cell">
    <div class="price" v-if="price">
      <a :href="hotelUrl">
        {{ price }}
      </a>
    </div>
    <div class="status btn_1 primary" v-else-if="status === 'full'">
      Complet
    </div>

    <p>
      <a :href="hotelUrl" class="btn_1" v-if="price">Voir les offres</a>
      <a :href="hotelUrl" class="btn_full btn_1 outline" v-else>Voir l’hôtel</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Price",
  props: {
    price: {
      type: String,
    },

    hotelUrl: {
      type: String,
      required: true,
    },

    status: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing * 2;

  p {
    margin: 0;
  }
}

.price {
  font-size: $size-text * 2;
  font-weight: 600;

  a {
    color: $primary;
  }
}

@media only screen and (min-width: 768px) {
  .cell {
    text-align: center;
    flex-direction: column;
    margin-top: $spacing * 2;
  }

  .price,
  .status {
    text-align: center;
    margin: 2 * $spacing 0;
  }
}
</style>
