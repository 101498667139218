<template>
  <Modal
    :isOpen="isOpen"
    :title="title"
    @onClose="handleModalClose"
    @afterClose="handleAfterClose"
    :large="large"
  >
    <component :is="component" @onClose="handleClose" v-bind="props" />
  </Modal>
</template>

<script>
// Modal architecture from https://github.com/DJanoskova/Vue.js-Modal-context
import Modal from "./Modal";
import EventBus from "../../EventBus";

export default {
  name: "ModalRoot",
  components: { Modal },

  data() {
    return {
      component: null,
      title: "",
      props: null,
      closeOnClick: true,
      isOpen: false,
      large: false,
    };
  },

  created() {
    EventBus.$on(
      "modal-open",
      ({
        component,
        title = "",
        props = null,
        closeOnClick = true,
        large = false,
      }) => {
        this.isOpen = true;
        this.component = component;
        this.title = title;
        this.props = props;
        this.closeOnClick = closeOnClick;
        this.large = large;

        document.body.classList.add("modal-opened");
      }
    );

    document.addEventListener("keyup", this.handleKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyup);
  },

  methods: {
    handleModalClose(force = false) {
      if (!this.closeOnClick && !force) return;
      this.handleClose();
    },

    handleClose() {
      this.isOpen = false;
      document.body.classList.remove("modal-opened");
    },

    handleKeyup(e) {
      if (e.keyCode === 27) this.handleClose();
    },

    handleAfterClose() {
      // wait for close transition to be finished before destroy component
      // otherwise we're seing component disappear before
      this.component = null;
    },
  },
};
</script>
