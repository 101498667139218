<template>
  <table>
    <thead>
      <tr>
        <th class="th-col-site left">Site</th>
        <th class="th-col-type left">Type de chambre</th>
        <th class="th-col-conditions conditions">Conditions</th>
        <th class="th-col-price">Prix</th>
        <th class="th-col-book book">En savoir +</th>
      </tr>
    </thead>
    <template v-for="seller in sellers">
      <seller v-bind="seller" :key="seller.name" />
    </template>
  </table>
</template>

<script>
import Seller from "./Seller";

export default {
  name: "Sellers",
  components: { Seller },
  props: {
    sellers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";
@import "../../../styles/table";

table {
  margin-bottom: 0;
}

.th-col-conditions {
  width: 208px;
}

.th-col-price {
  width: 92px;
}

.th-col-book {
  width: 160px;
}
</style>
