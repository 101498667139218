/* eslint-disable no-new */

import Vue from "vue";
import ModalRoot from "./ModalRoot.vue";

document.addEventListener("js-ready", () => {
  const container = "modal";

  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: { ModalRoot },
    });
  }
});
