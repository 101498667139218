/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import HotelRoomsAvailabilities from "./index.vue";

document.addEventListener("js-ready", () => {
  const container = "hotel-rooms-availabilities";

  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: { HotelRoomsAvailabilities },
    });
  }
});
