<template>
  <div>
    <p class="name">{{ name }}</p>

    <p class="secondary-props">
      <i class="icon-adult" aria-hidden="true" />
      {{ maxOccupancy | occupancy }}

      <span v-if="roomSurface"> – {{ roomSurface }} m²</span>
    </p>

    <span
      v-if="picture"
      data-href="detailsUrl"
      class="link-photo"
      @click="handleDetails"
    >
      <img :src="picture" :alt="name" />
      <span class="link-zoom">
        <span class="link-zoom-core">
          <span class="link-zoom-text">
            <i class="icon-zoom-in" aria-hidden="true" />
          </span>
        </span>
      </span>
    </span>

    <p v-if="description">
      <span class="hidden-sm" v-html="descriptionHtml" />
      <button v-if="detailsUrl" @click="handleDetails" class="link-more">
        + d'infos
      </button>
    </p>
  </div>
</template>

<script>
import RoomDetails from "../RoomDetails";
import EventBus from "../../EventBus";
import { occupancy, textToHtml } from "../../../utils/filters";

export default {
  name: "Room",
  props: {
    name: {
      type: String,
      required: true,
    },
    maxOccupancy: {
      type: Number,
      default: null,
    },
    roomSurface: {
      type: Number,
      default: null,
    },
    picture: {
      type: String,
      default: null,
    },
    detailsUrl: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },

  methods: {
    handleDetails(event) {
      if (event) {
        event.preventDefault();
      }

      EventBus.$emit("modal-open", {
        component: RoomDetails,
        props: {
          url: this.detailsUrl,
          surface: this.roomSurface,
          maxOccupancy: this.maxOccupancy,
        },
        title: this.name,
        large: true,
      });
    },
  },

  computed: {
    descriptionHtml() {
      return textToHtml(this.description);
    },
  },

  filters: {
    occupancy,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/table";

.name {
  font-size: $size-text;
  position: relative;
  color: $black;
  font-weight: 600;
}

.name,
.secondary-props {
  margin-bottom: 0.5em;
}

.secondary-props {
  color: $gray-strong;
  i {
    font-size: 1.2em;
  }
}

.link-photo {
  position: relative;
  float: left;
  margin: 0 15px 5px 0;
  cursor: pointer;
  width: 60px;
  overflow: hidden;

  img {
    box-sizing: border-box;
    border: 1px solid $gray-medium;
    border-bottom-color: $black;
    width: 60px;
    max-width: 100%;
    height: 60px;
  }
}

.link-zoom {
  font-size: $size-small;
  position: absolute;
  right: -3em;
  bottom: -2.75em;
  text-align: left;
  color: $white;

  &-core {
    display: inline-block;
    border: 1px solid $white;
    border-radius: 100% 100% 0 0;
    background: rgba($black, 0.9);
    padding: 1.5em 2.5em;
    vertical-align: middle;
  }

  &-text {
    position: relative;
    right: 1em;
    bottom: 1em;

    i {
      vertical-align: top;
      font-size: $size-text;
    }
  }
}

.link-more {
  white-space: nowrap;
  color: $tertiary;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;

  &:hover {
    color: $black;
  }
}
</style>
