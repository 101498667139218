var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"strip_all_tour_list"},[_c('div',{staticClass:"row row-no-gutters equal"},[_c('div',{staticClass:"col-xs-12 col-sm-4 photo-wrapper"},[_c('a',{staticClass:"hotel-photo",attrs:{"href":_vm._f("toDescription")(_vm.url),"title":_vm.name}},[_c('img',{attrs:{"alt":_vm.name,"src":_vm.imageUrl,"loading":"lazy"}})])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-5 detail-wrapper"},[_c('div',{staticClass:"tour_list_desc"},[_c('div',{staticClass:"hotel-header"},[_c('div',{staticClass:"hotel-name"},[_c('h3',[_c('strong',[_c('a',{attrs:{"href":_vm._f("toDescription")(_vm.url)}},[_vm._v(_vm._s(_vm.name))])])]),_vm._v(" "),_c('div',{staticClass:"rating"},_vm._l((_vm.stars),function(n){return _c('i',{key:n,staticClass:"icon-star voted"})}),0)]),_vm._v(" "),(_vm.reviewScore)?_c('div',{class:['score', ("score-" + (Math.floor(this.reviewScore)))]},[_c('span',{staticClass:"score-note"},[_vm._v(_vm._s(_vm.reviewScore))]),_vm._v(" "),_c('span',{staticClass:"score-label"},[_vm._v(_vm._s(_vm.reviewLabel))])]):_vm._e()]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.excerpt))]),_vm._v(" "),(_vm.quartersIds.length)?_c('div',{staticClass:"quarters"},[_vm._v("\n          "+_vm._s("Quartier")+" :\n          "),_vm._l((_vm.quartersIds),function(quarterId){return _c('QuarterLink',_vm._b({key:quarterId},'QuarterLink',_vm.quarters.find(function (ref) {
	var id = ref.id;

	return id === quarterId;
}),false))})],2):_vm._e(),_vm._v(" "),(_vm.facilitiesIds.length)?_c('ul',{staticClass:"hotel-facilities"},_vm._l((_vm.facilitiesIds),function(facilityId){return _c('Facility',_vm._b({key:facilityId},'Facility',_vm.siteFacilities.find(function (ref) {
	var id = ref.id;

	return id === facilityId;
}),false))}),1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-xs-10 col-xs-offset-1 col-sm-3 col-sm-offset-0 prices-wrapper"},[_c('Price',{attrs:{"price":_vm.price,"status":_vm.status,"hotelUrl":_vm._f("toAvailabilities")(_vm.url)}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }