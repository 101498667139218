const onSubjectChanged = (event) => {
  const form = document.getElementById("new_contact_form");

  const { value } = event.target;

  const fillableSubjects = ["", "partnership", "hotel_edit"];

  if (fillableSubjects.includes(value)) {
    if (form.length <= 3) {
      // redirect from "Contact hotel" limited form to full form
      const query = `${encodeURIComponent(
        "contact_form[subject]"
      )}=${encodeURIComponent(value)}`;

      window.location = `${window.location.pathname}?${query}#position`;
    }

    return;
  }

  form.submit();
};

document.addEventListener("DOMContentLoaded", () => {
  const select = document.getElementById("contact_form_subject");

  if (!select) {
    return;
  }

  select.addEventListener("change", onSubjectChanged);
});
