<template>
  <div class="group">
    <h6 class="title">{{ title }}</h6>
    <ul class="list">
      <li v-for="filter in filters" :key="filter.value">
        <div class="checkbox">
          <input
            type="checkbox"
            :name="inputName"
            :id="idify(inputName, filter.value)"
            :value="filter.value"
            :checked="filter.active"
            @change="onChange"
          />
          <label :for="idify(inputName, filter.value)">{{
            filter.label
          }}</label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FilterGroup",
  props: {
    title: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    filters: {
      type: Array,
      required: true,
    },

    onChange: {
      type: Function,
    },
  },

  data() {
    return {
      inputName: `search[${this.name}][]`,
    };
  },

  methods: {
    idify: (name, value) => `${name}_${value}`.replace(/\W/g, "_"),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.group {
  border-top: 1px solid $border-light;
  margin: $spacing * 2 0;
  padding: $spacing * 2 0 0 0;
}

.title {
  font-weight: 700;
  color: $black-light;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 3 0;

  label {
    font-weight: normal;
    color: $black-light;
    display: block;

    &:hover {
      color: $black;
    }
  }
}

.rating {
  position: relative;
  font-size: $size5;
  left: -7px;
  top: 3px;
}
</style>
