<template>
  <div>
    <p :class="{ alert: true, 'has-background': hasBackground, soft: soft }">
      {{ error }}
      <button @click="$emit('retry')" v-if="hasRetry">
        Veuillez réessayer.
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorMsg",
  props: {
    error: {
      type: String,
      required: true,
    },

    hasBackground: {
      type: Boolean,
      default: false,
    },

    retry: {
      type: Boolean,
      default: null,
    },

    soft: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasRetry() {
      return !!this.$listeners.retry;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.alert {
  font-size: $size6;
  font-weight: 600;
  color: $error;

  &.has-background {
    background-color: rgba($primary, 0.06);
    border-color: rgba($primary, 0.06);
  }

  &.soft {
    color: $primary;
  }
}

button {
  background: none;
  border: none;
  outline: none;
  padding: 2px;
  margin: 0;

  &:hover {
    background-color: $border-light;
    color: $black;
  }
}
</style>
