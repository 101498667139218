<template>
  <div v-if="checkin && checkout" class="wrapper">
    <h2 class="title">
      Disponibilités et prix des chambres
      <DateRangeSummary
        v-if="checkin && checkout"
        :checkin="checkin"
        :checkout="checkout"
      />
    </h2>
    <div v-if="isLoading || error || !hasRooms" class="text-center feedback">
      <Loader v-if="isLoading" large />
      <ErrorMsg v-else-if="error" :error="error" />
      <ErrorMsg
        v-else-if="!hasRooms"
        soft
        error="L'hôtel est complet. Toutes les chambres ont déjà été réservées."
      />
    </div>
    <div v-if="!isLoading && !error && hasRooms">
      <rooms-form
        :rooms="rooms"
        :hotelId="hotelId"
        :checkin="checkin"
        :checkout="checkout"
      />
    </div>
  </div>
</template>

<script>
import RoomsForm from "./RoomsForm";
import EventBus from "../../EventBus";
import DateRangeSummary from "../../shared/DateRangeSummary";
import ErrorMsg from "../../shared/ErrorMsg";
import Loader from "../../shared/Loader";
import { elementTopIntoView, scrollTopIntoView } from "../../../utils/ui";

export default {
  name: "HotelRoomsAvailabilities",
  components: { RoomsForm, DateRangeSummary, Loader, ErrorMsg },
  props: {
    hotelId: {
      type: String,
      required: true,
    },
    initialCheckin: {
      type: String,
    },

    initialCheckout: {
      type: String,
    },
  },

  mounted() {
    EventBus.$on("hotel-search-submit", ({ checkin, checkout, adults }) => {
      this.checkin = checkin;
      this.checkout = checkout;
      this.adults = adults;

      this.fetchRoomsAvailabilities();

      // don't scroll at initial load
      if (document.readyState === "complete") {
        // wait for table loaders to be displayed
        // otherwise browser fail to calculate position
        setTimeout(() => {
          const roomsEvailabilitiesEl = document.getElementById(
            "hotel-rooms-availabilities"
          );
          if (!elementTopIntoView(roomsEvailabilitiesEl)) {
            scrollTopIntoView(roomsEvailabilitiesEl);
          }
        }, 200);
      }
    });
  },

  methods: {
    fetchRoomsAvailabilities() {
      if (!this.checkin || !this.checkout) {
        return;
      }

      this.isLoading = true;
      this.rooms = null;
      this.policies = null;
      this.error = null;

      const path = `/hotels/${this.hotelId}/${this.checkin}/${this.checkout}/${this.adults}/rooms_availabilities.json`;
      const finallyFn = () => {
        this.isLoading = false;
      };

      fetch(path, {
        headers: {
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.error = data.error.message;
          } else {
            this.rooms = data.rooms;
            this.policies = data.policies;
          }

          finallyFn();
        })
        .catch(() => {
          this.error = "Une erreur est survenue. Veuillez réessayer.";
          finallyFn();
        });
    },
  },

  data() {
    return {
      checkin: this.initialCheckin,
      checkout: this.initialCheckout,
      isLoading: false,
      error: null,
      rooms: null,
      policies: null,
    };
  },

  computed: {
    hasRooms() {
      return this.rooms.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/table-availabilities";
</style>
