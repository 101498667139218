<template>
  <SearchByName :isLoading="isLoading" :error="error" :hotels="hotels" />
</template>

<script>
import SearchByName from "./SearchByName";

export default {
  name: "SearchByNameContainer",
  components: { SearchByName },
  mounted() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      this.isLoading = true;
      return fetch("/", {
        headers: {
          accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.error = data.error.message;
          } else {
            this.hotels = data.hotels;
          }
        })
        .catch(() => {
          this.error = "Une erreur est survenue.";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  data() {
    return {
      isLoading: false,
      error: null,
      hotels: [],
    };
  },
};
</script>
