<template>
  <div class="quantity-change">
    <button
      v-on:click="decrement"
      v-bind:disabled="!canDecrement"
      class="decrement"
    >
      -
    </button>

    <input
      :name="name"
      class="form-control"
      type="text"
      :value="value"
      readonly
    />

    <button
      v-on:click="increment"
      v-bind:disabled="!canIncrement"
      class="increment"
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  name: "QuantityChange",
  props: {
    initialValue: {
      type: Number,
      default: 0,
    },

    name: {
      type: String,
      required: true,
    },

    min: {
      type: Number,
      default: 0,
    },

    max: Number,
  },

  data() {
    return {
      value: this.initialValue,
    };
  },

  computed: {
    canDecrement() {
      return this.value > this.min;
    },

    canIncrement() {
      return this.max !== undefined && this.value < this.max;
    },
  },

  methods: {
    increment(event) {
      if (event) event.preventDefault();

      if (this.canIncrement) {
        this.value += 1;
      }
    },

    decrement(event) {
      if (event) event.preventDefault();

      if (this.canDecrement) {
        this.value -= 1;
      }
    },
  },

  watch: {
    value(newValue) {
      this.$emit("change", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-change {
  display: flex;
}

input,
button {
  width: 35px;
  height: 40px;
  border: 1px solid #ccc;
  background-color: #fff !important;
  line-height: 16px;
}

input {
  padding: 5px;
  text-align: center;
  border-radius: 0;
  border-width: 1px 0;
}

button {
  font-family: monospace;
  font-size: 16px;
  color: #999;
}

.decrement {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.increment {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
