<template>
  <div class="animated fadeInDownSmall faster">
    <Form
      v-bind:checkin="checkin"
      v-bind:checkout="checkout"
      v-bind:adults="adults"
      v-bind:group-url-base="groupUrlBase"
      @dates-change="handleDatesChange"
    />

    <BroaderButton
      v-bind:checkin="checkin"
      v-bind:checkout="checkout"
      v-bind:adults="adults"
    />
  </div>
</template>

<script>
import Form from "./Form";
import BroaderButton from "./BroaderButton";

export default {
  name: "HotelSearch",
  props: {
    initialCheckin: {
      type: String,
    },

    initialCheckout: {
      type: String,
    },

    initialAdults: {
      type: Number,
      required: true,
    },

    groupUrlBase: {
      type: String,
      required: true,
    },

    searchId: {
      type: String,
    },
  },

  methods: {
    handleDatesChange({ checkin, checkout }) {
      this.checkin = checkin;
      this.checkout = checkout;
    },
  },

  data() {
    return {
      checkin: this.initialCheckin,
      checkout: this.initialCheckout,
      adults: this.initialAdults,
    };
  },

  components: {
    Form,
    BroaderButton,
  },
};
</script>

<style lang="scss">
@keyframes fadeInDownSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownSmall {
  -webkit-animation-name: fadeInDownSmall;
  animation-name: fadeInDownSmall;
}
</style>
