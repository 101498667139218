import EventBus from "../EventBus";
import SearchByNameContainer from "./index.vue";

document.addEventListener("js-ready", () => {
  const button = document.getElementById("search_by_name_button");

  if (!button) {
    return;
  }

  const onButtonClick = (event) => {
    if (event) {
      event.preventDefault();
    }

    EventBus.$emit("modal-open", {
      component: SearchByNameContainer,
      title: button.dataset.title,
    });
  };

  button.addEventListener("click", onButtonClick);
});
