<template>
  <div v-click-outside="hidePicker">
    <output
      :class="{
        'form-control summary': true,
        'has-error': !!error,
      }"
      data-sync-id="search_checkin_date"
      @click="openPicker"
    >
      {{ checkinString }} <span style="color: #999999;">—</span>
      {{ checkoutString }}

      <span v-if="nightsString">({{ nightsString }})</span>
    </output>

    <div class="error-msg" v-if="error">
      {{ error }}
    </div>

    <div class="picker-container" v-show="pickerVisible">
      <DateRangePicker
        class="picker"
        v-bind:past="false"
        v-bind:yearsCount="1"
        :from="from"
        :to="to"
        locale="fr"
        panel="range"
        v-bind:panels="[]"
        v-bind:presets="[]"
        v-bind:showControls="false"
        v-bind:theme="{
          primary: '#1ca189',
          secondary: '#333333',
          ternary: '#565a5c',
          border: '#e6e6e6',
          light: '#ffffff',
          dark: '#000000',
          hovers: { day: '#CCC', range: '#e6e6e6' },
        }"
        @select="select"
      />
    </div>
  </div>
</template>

<script>
import "vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css";
import { differenceInDays, parseISO, startOfDay } from "date-fns";
import { iso8601, formatDate } from "../../../utils/date";

export default {
  name: "DateRangeInput",
  props: ["checkin", "checkout"],

  methods: {
    openPicker() {
      this.pickerVisible = true;
      this.error = null;
    },

    select({ from, to }) {
      const fromDate = iso8601(parseISO(from));
      const toDate = iso8601(parseISO(to));
      const oldFromDate = this.from && iso8601(parseISO(this.from));
      const oldToDate = this.to && iso8601(parseISO(this.to));

      // Some users may double click on checkin date to valid it,
      // which results to validate only a checkin date (because checkout must be > checkin).
      // So, they're trying to choose a checkout date the same way.
      // Without the following trick, our datepicker
      // would erase checkin with the new date picked,
      // but still without checkout because they clicked twice again
      // to "valid" the date.
      // With this trick, we're using the second pick
      // as the checkout date if the conditions are met.
      const selectToApart =
        oldFromDate &&
        oldFromDate === oldToDate &&
        toDate > oldFromDate &&
        fromDate === toDate;
      if (!selectToApart) {
        this.from = from;
      }

      this.to = to;

      // select event is emitted immediately at picker mount if initialized with from & to
      // Because we don't want to close it in this case,
      // we're waiting a little bit to catch a real user selection
      if (from && to && this.pickerOpenedAt + 500 < new Date().getTime()) {
        this.hidePicker();
      }
    },

    hidePicker() {
      this.pickerVisible = false;
      if (this.from && this.to) {
        this.$emit("dates-change", {
          checkin: iso8601(this.fromDate),
          checkout: iso8601(this.toDate),
        });
      }
    },

    isValid() {
      this.error = null;

      if (!this.to || !this.from) {
        this.error = "Saisissez vos dates de voyage.";
      } else if (this.fromDate && !this.toDate) {
        this.error = "Saisissez la date de fin du séjour.";
      } else if (!this.validCheckout) {
        this.error =
          "La date de départ doit être postérieure à la date d'arrivée.";
      } else if (this.nights > 15) {
        this.error = "Votre séjour ne peut excéder 15 nuits.";
      }

      return !this.error;
    },
  },

  computed: {
    validCheckout() {
      if (!this.fromDate || !this.toDate) {
        return false;
      }

      return this.toDate > this.fromDate;
    },

    fromDate() {
      if (this.from) {
        return startOfDay(parseISO(this.from));
      }

      return null;
    },

    toDate() {
      if (this.to) {
        return startOfDay(parseISO(this.to));
      }

      return null;
    },

    checkinString() {
      return this.from
        ? formatDate(this.from, { month: undefined })
        : "Arrivée";
    },

    checkoutString() {
      return this.validCheckout ? formatDate(this.to) : "Départ";
    },

    nights() {
      if (this.validCheckout) {
        return differenceInDays(this.toDate, this.fromDate);
      }

      return null;
    },

    nightsString() {
      if (this.nights) {
        return `${this.nights} nuit${this.nights > 1 ? "s" : ""}`;
      }

      return null;
    },
  },

  watch: {
    pickerVisible(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.pickerOpenedAt = new Date().getTime();
      }
    },
  },

  directives: {
    "click-outside": {
      bind(el, binding, vnode) {
        const clickOutsideEvent = (event) => {
          // don't search element if the picker is not visible (performance optimization)
          if (!vnode.context.pickerVisible) {
            return;
          }

          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };

        el.clickOutsideEvent = clickOutsideEvent; // eslint-disable-line

        document.body.addEventListener("click", el.clickOutsideEvent);
      },

      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },

  data() {
    return {
      pickerVisible: false,
      pickerOpenedAt: undefined,
      from: this.checkin,
      to: this.checkout,
      error: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/variables";

.summary {
  line-height: 28px /* 40px (height) - 2*6px (padding vertical) */;

  &.has-error {
    border-color: $error;
  }
}

.picker-container {
  position: relative;
  width: 100%;
}

.picker {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mj-daterange-picker {
  width: 100%;
  min-width: 300px;
}

.error-msg {
  color: $error;
}
</style>
