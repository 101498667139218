<template>
  <tr>
    <slot name="room"></slot>

    <td class="conditions">
      <div class="th-mobile hidden-sm hidden-md hidden-lg">Conditions</div>
      <div class="td-mobile">
        <ul class="list-conditions">
          <li :class="{ off: !breakfastIncluded }">
            <i :class="breakfastIcon" aria-hidden="true" />
            <span v-if="breakfastIncluded">Petit-déj. inclus</span>
            <span v-else>
              Petit déj. non inclus
            </span>
          </li>

          <li :class="{ off: !cancellable }">
            <i :class="cancellableIcon" aria-hidden="true" />
            {{ cancellableLabel }}
          </li>
        </ul>
      </div>
    </td>
    <td class="td-price">
      <div class="th-mobile hidden-sm hidden-md hidden-lg">Prix</div>
      <div class="td-mobile">
        <span class="price">{{ formattedPrice }}</span>
      </div>
    </td>
    <td class="book">
      <div class="th-mobile hidden-sm hidden-md hidden-lg">Réserver</div>
      <div class="td-mobile">
        <div class="rooms-count clearfix">
          <quantity-change
            ref="quantity"
            :name="fieldNameBase + '[quantity]'"
            :max="quantity"
            @change="handleQuantityChanged"
          />
        </div>

        <div class="action" v-if="isSelected">
          <button class="btn_full" @click="submit" type="submit">
            <Loader v-if="isSubmitting" />
            <template v-else>Réserver</template>
          </button>
        </div>

        <span v-if="quantity < 4" class="rooms-count-hint">
          {{ quantity | remainingQuantity }}
        </span>

        <input
          type="hidden"
          :name="fieldNameBase + '[max_quantity]'"
          :value="quantity"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import Loader from "../../shared/Loader";
import QuantityChange from "../../shared/quantity_change/index.vue";

export default {
  name: "Availability",
  components: { QuantityChange, Loader },
  props: {
    nativeId: {
      type: String,
      required: true,
    },

    breakfastPrice: {
      type: String,
    },

    breakfastIncluded: {
      type: Boolean,
      required: true,
    },

    cancellable: {
      type: Boolean,
      required: true,
    },

    cancellableLabel: {
      type: String,
      required: true,
    },

    formattedPrice: {
      type: String,
      required: true,
    },

    price: {
      type: Number,
      required: true,
    },

    quantity: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      isSubmitting: false,
      isSelected: false,
    };
  },

  methods: {
    handleQuantityChanged(count) {
      this.isSelected = count > 0;

      this.$emit("select", {
        nativeId: this.nativeId,
        count,
        unitPrice: this.price,
      });
    },

    submit() {
      this.isSubmitting = true;

      setTimeout(() => {
        this.isSubmitting = false;
      }, 2000);
    },
  },

  computed: {
    breakfastIcon() {
      return {
        "icon-coffee": this.breakfastIncluded || this.breakfastPrice,
        green: this.breakfastIncluded,
        "icon-block": !this.breakfastIncluded && !this.breakfastPrice,
      };
    },

    cancellableIcon() {
      return {
        "icon-euro": this.cancellable,
        green: this.cancellable,
        "icon-block": !this.cancellable,
      };
    },

    fieldNameBase() {
      return `room_availability[${this.nativeId}]`;
    },
  },

  filters: {
    remainingQuantity(quantity) {
      if (quantity === 1) {
        return "Plus qu'une disponible";
      }

      if (quantity > 1) {
        return `Plus que ${quantity} disponibles`;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/table";
@import "../../../styles/table-availabilities";

.rooms-count + .action {
  margin-top: 4px;
}

.rooms-count-hint {
  font-size: $size-small;
  display: block;
  margin-top: 4px;
  color: $disabled;
}
</style>
