<template>
  <form :action="actionUrl" method="POST" target="_blank">
    <table>
      <thead>
        <tr>
          <th class="left" scope="col">Type de chambre</th>
          <th class="th-col-conditions conditions" scope="col">Conditions</th>
          <th class="th-col-price" scope="col">Prix</th>
          <th class="th-col-book book" scope="col">Chambres à réserver</th>
        </tr>
      </thead>
      <template v-for="(room, index) in rooms">
        <tbody :key="index">
          <template v-for="(availability, index) in room.availabilities">
            <availability
              v-bind="availability"
              :key="availability.nativeId"
              @select="handleSelect"
            >
              <template v-slot:room>
                <td class="hidden-sm hidden-md hidden-lg">
                  <div class="th-mobile">Type de chambre</div>
                  <div
                    :class="{
                      'td-mobile': true,
                      'visible-xs-block': room.availabilities.length > 0,
                    }"
                  >
                    <room v-bind="room.properties" />
                  </div>
                </td>

                <td
                  v-if="index === 0"
                  class="hidden-xs"
                  :rowspan="room.availabilities.length"
                >
                  <room v-bind="room.properties" />
                </td>
              </template>
            </availability>
          </template>
        </tbody>
      </template>
      <Summary :roomsCount="roomsCount" :totalPrice="totalPrice" />
    </table>

    <input type="hidden" name="checkin_date" :value="checkin" />
    <input type="hidden" name="checkout_date" :value="checkout" />
  </form>
</template>

<script>
import Availability from "./Availability";
import Room from "./Room";
import Summary from "./Summary";

export default {
  name: "RoomsForm",
  components: { Availability, Room, Summary },
  props: {
    hotelId: {
      type: String,
      required: true,
    },

    checkin: {
      type: String,
      required: true,
    },

    checkout: {
      type: String,
      required: true,
    },

    policies: {
      type: Array,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
    };
  },

  methods: {
    handleSelect(room) {
      const newSelected = this.selected.filter(
        ({ nativeId }) => nativeId !== room.nativeId
      );

      if (room.count > 0) {
        newSelected.push(room);
      }

      this.selected = newSelected;
    },
  },

  computed: {
    actionUrl() {
      return `/hotels/${this.hotelId}/goto`;
    },

    roomsCount() {
      return this.selected.reduce((total, { count }) => total + count, 0);
    },

    totalPrice() {
      return this.selected.reduce(
        (total, { count, unitPrice }) => total + count * unitPrice,
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";
@import "../../../styles/table";

.th-col-conditions {
  width: 196px;
}

.th-col-price {
  width: 92px;
}

.th-col-book {
  width: 160px;
}
</style>
