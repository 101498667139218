<template>
  <section :data-gallery-id="id">
    <div class="row">
      <div class="col-xs-12">
        <agile
          class="hotel-photos"
          ref="main"
          :options="options1"
          :as-nav-for="asNavFor1"
        >
          <div
            class="slide"
            v-for="({ url, legend }, index) in photos"
            :key="index"
            :class="`slide--${index}`"
          >
            <img
              :src="url"
              :alt="legend || `Photo ${index + 1}`"
              loading="lazy"
            />
            <p v-if="legend" class="legend">{{ legend }}</p>
          </div>
          <template v-slot:prevButton>
            <i class="icon-left-open" />
          </template>

          <template v-slot:nextButton>
            <i class="icon-right-open" />
          </template>
        </agile>
      </div>

      <div class="col-xs-12">
        <agile
          class="thumbnails"
          ref="thumbnails"
          :options="options2"
          :as-nav-for="asNavFor2"
        >
          <div
            class="slide slide--thumbnail"
            v-for="({ url, legend }, index) in thumbnails"
            :key="index"
            :class="`slide--${index}`"
            @click="$refs.thumbnails.goTo(index)"
          >
            <img
              :src="url"
              :alt="legend || `Photo ${index + 1}`"
              loading="lazy"
            />
          </div>
        </agile>
      </div>
    </div>
  </section>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  components: {
    agile: VueAgile,
  },

  props: {
    photos: {
      type: Array,
      required: true,
    },

    thumbnails: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      id: this.generateId(),
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: true,
        fade: true,
        infinite: true,
        navButtons: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: false,
              navButtons: true,
            },
          },
        ],
      },
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        infinite: true,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 992,
            settings: {},
          },
        ],
      },

      overrideTranslateX() {
        const {
          currentSlide,
          widthSlide,
          slidesCount,
          settings,
        } = this.$refs.thumbnails;

        if (currentSlide + settings.slidesToShow > slidesCount) {
          const translateX =
            -1 * widthSlide * (slidesCount - settings.slidesToShow);

          this.$refs.thumbnails.translateX = translateX;
        }
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.asNavFor1.push(this.$refs.thumbnails);
      this.asNavFor2.push(this.$refs.main);

      // override slider translateX property
      // in order to always have thumbnails
      this.translateXWatcher = this.$watch(
        () => this.$refs.thumbnails.translateX,
        (newTranslateX) => {
          if (newTranslateX < 0) {
            this.overrideTranslateX();
          }
        },
        { immediate: true }
      );

      // Workaround with a max-height to avoid portrait ratio photos
      // We set a max height for a 4/3 ratio.
      // Wrapped in a function to wait for next tick and img being inserted in DOM
      const selector = `[data-gallery-id=${this.id}] .hotel-photos`;
      function setMaxHeight() {
        const width = document.querySelector(selector).clientWidth;

        if (!width) {
          return;
        }

        const maxHeight = `${Math.round(width / (4 / 3))}px`;

        const imgs = document.querySelectorAll(`${selector} .slide img`);

        imgs.forEach((img) => {
          img.style.maxHeight = maxHeight; // eslint-disable-line
        });
      }
      setMaxHeight();
    });
  },

  beforeDestroy() {
    if (this.translateXWatcher) {
      this.translateXWatcher();
    }
  },

  methods: {
    generateId() {
      return `hp-${((Math.random() * 0xffffff) << 0) // eslint-disable-line
        .toString(16)
        .padStart(6, "0")}`;
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/_variables";

// Basic VueAgile styles
.agile {
  &__actions {
    @media screen and (min-width: $screen-md-min) {
      position: absolute;
      top: 50%;
      width: 100%;
      opacity: 0;
      transition: opacity 0.5s ease;
    }
  }

  &:hover {
    .agile__actions {
      opacity: 1;
    }
  }

  &__nav-button {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 32px;
    transition-duration: 0.3s;

    &:hover {
      color: #bbb;
    }

    &[disabled] {
      visibility: hidden;
    }
  }

  &__dots {
    bottom: $spacing;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &__dot {
    margin: 0 10px;

    button {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      width: 10px;
    }

    &--current {
      button,
      &:hover button {
        background-color: #fff;
      }
    }
  }

  &__slides {
    @media screen and (max-width: $screen-sm-max) {
      align-items: flex-end; // align at bottom the img when we have dots, so they are always above the img
    }

    @media screen and (min-width: $screen-md-min) {
      align-items: flex-start;
    }
  }

  &.thumbnails .agile__list {
    overflow-x: clip;
    overflow-y: visible;
  }
}
</style>

<style lang="scss" scoped>
.hotel-photos {
  margin-bottom: 30px;
}

.legend {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  margin-bottom: 0;
  color: #333;
  background-color: rgba(255, 255, 255, 0.88);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-size: 12px;
}

// Slides styles
.slide {
  align-items: flex-start;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  max-height: 450px;
  justify-content: flex-start;
  flex-direction: column;

  &--thumbnail {
    cursor: pointer;
    height: 100px;
    padding: 0 5px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.75;
    }

    &.agile__slide--active {
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        width: 60%;
        left: 20%;
        background-color: #444;
        height: 2px;
        top: -6px;
      }
    }

    img {
      height: 70%;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}
</style>
