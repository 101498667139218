<template>
  <div class="group">
    <h6 class="title">Prix par nuit</h6>
    <div class="slider-container">
      <vue-slider
        v-model="value"
        :min="0"
        :max="200"
        :interval="10"
        :marks="markLabel"
        :tooltip="'always'"
        :tooltipFormatter="(val) => (val === 200 ? '200€+' : `${val}€`)"
        :processStyle="processStyle"
        :tooltipStyle="tooltipStyle"
        :labelStyle="{ fontSize: '11px' }"
        :stepStyle="{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }"
        @change="onChange"
      />
      <input
        type="hidden"
        v-model="value"
        name="search[price_range]"
        readonly
      />
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

const sliderStyleBase = { backgroundColor: "#cf4a64", borderColor: "#cf4a64" };

export default {
  components: {
    VueSlider,
  },
  props: {
    initialValue: {
      type: Array,
      required: true,
    },

    onChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      processStyle: {
        ...sliderStyleBase,
      },

      tooltipStyle: {
        ...sliderStyleBase,
        padding: "1px 3px",
        fontSize: "11px",
      },
      value: this.initialValue,
      markLabel: (val) => {
        if (val % 50 !== 0) {
          return false;
        }

        return {
          label: val === 200 ? "200+€" : `${val}€`,
        };
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.group {
  border-top: 1px solid $border-light;
  margin: $spacing * 2 0;
  padding: $spacing * 2 0 0 0;
}

.title {
  font-weight: 700;
  color: $black-light;
}

.slider-container {
  margin: $spacing * 5 $spacing * 1.5;
}
</style>
